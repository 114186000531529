import React from 'react';
import { storyblokEditable, StoryblokComponent } from '@storyblok/react';

/**
 @tailwind unpurge
 md:grid-cols-12
 md:col-span-1 md:col-span-2 md:col-span-3 md:col-span-4 md:col-span-5 md:col-span-6 md:col-span-7 md:col-span-8 md:col-span-9 md:col-span-10 md:col-span-11 md:col-span-12
 order-first
 md:order-none

 */

export default function TwoColumns({ blok }) {
  const { columns = [], verticalAlignment = '', breakpoint = 'md', leftColumnWidth = 6, className = '', leftColumnClass = '', rightColumnClass = '' } = blok;
  const rightColumnWidth = 12 - leftColumnWidth;

  return (
    <div className={`grid ${breakpoint}:grid-cols-12 gap-8 ${verticalAlignment} ${className}`} {...storyblokEditable(blok)}>
      <div className={`flex flex-col md:col-span-${leftColumnWidth} ${leftColumnClass}`}>
        <StoryblokComponent blok={columns[0]} />
      </div>

      <div className={`flex flex-col md:col-span-${rightColumnWidth} ${rightColumnClass}`}>
        <StoryblokComponent blok={columns[1]} />
      </div>
    </div>
  );
}
