import React, { useEffect } from 'react'
import { useRouter } from 'next/router';
import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import ModalVideo from 'react-modal-video';
import useStore from "~/hooks/useStore";
import { useRouteHash } from '~/hooks/useRouteHash';


import styled from 'styled-components';

const Div = styled.div`
  &.aspect-ratio-none {
    .modal-video-movie-wrap {
      padding-bottom: 0!important;
      width: fit-content!important;
      margin: auto!important;
      height: auto!important;
    }
  }
`;

export default function Modal({ blok }) {

  const [modalOpen, setModalOpen] = [useStore((state) => state.modalOpen), useStore((state) => state.setModalOpen)];
  const hash = useRouteHash();
  const router = useRouter();
  useEffect(()=>{
    if (!hash) return setModalOpen(null);
    setModalOpen(hash.slice(1));
  }, [hash, router]);

  return (
    <Div {...storyblokEditable(blok)} className={`aspect-ratio-${blok.aspectRatio}`}>
      <ModalVideo isOpen={modalOpen === blok.id} onClose={() => { window.location.hash = ''; setModalOpen(null); }}
      // style={{ width: 'fit-content', margin: 'auto', height: '72vh' }}
      >
        {blok?.content?.map(nestedBlok => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </ModalVideo>
    </Div>
  )
}
