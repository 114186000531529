import React from 'react';
import Link from 'next/link';
import { storyblokEditable } from '@storyblok/react';

export default function Button({ blok }) {
  const buttonType = blok.type || 'default-button';
  const classes = {
    'default-button': 'btn',
    'default-blue-button': 'btn btn-blue',
    'outline-button': 'btn outline-button',
    'outline-button-grey': 'btn outline-button outline-button--grey',
    'outline-button-dark-text': 'btn outline-button text-black',
    'text-link': 'text-link',
    'text-link--yellow': 'text-link text-link--yellow',
    'arrow-text-link': 'text-link text-link--arrow'
  };

  return (
    <div {...storyblokEditable(blok)} className={`relative ${blok?.description?.length > 0 ? 'mb-8' : ''}`}>
      {blok?.title ? (
        <Link href={blok?.link?.url || '#'}>
          <a className={`${classes[buttonType]}`} target={blok?.link?.target} dangerouslySetInnerHTML={{ __html: blok?.title }} />
        </Link>
      ) : null}
      {blok?.description?.length > 0 ? (
        <div className="opacity-50 text-14px pt-2 absolute inset-0 bottom-auto text-center" style={{ top: '100%' }}>
          {blok.description}
        </div>
      ) : null}
    </div>
  );
}
