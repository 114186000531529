import React from 'react';
import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import styled from 'styled-components';

const ColWrapper = styled.div`
padding:${props => props.divider === true ? '':''};
  @media screen and (min-width: 768px) {
    border-left:${props => props.i > 0 && props.divider === true ? '1px':'0'} dashed #000;
  }
`;



export default function ThreeColumns({ blok }) {
  const { columns = [], verticalAlignment = '', breakpoint = 'md', divider=false } = blok;
  return (
    <div className={`grid ${breakpoint}:grid-cols-3 gap-6 ${verticalAlignment}`} {...storyblokEditable(blok)}>
      {columns.map((column, i) => (
        <ColWrapper i={i} divider={divider} className={`flex flex-col ${divider && i > 0 ? 'md:pl-6': '' }`}  key={column._uid}>
          <StoryblokComponent blok={column} />
        </ColWrapper>
      ))}
    </div>
  );
}
